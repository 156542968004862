.details-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 3;
  padding: 0.8rem 4rem;
  height: 60vh;
  overflow-y: scroll;
}
.total-bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 25rem;
}

@media screen and (max-width: 800px) {
  .details-list {
    padding: 0.2rem 0.5rem;
    height: 70vh;
    flex: 4;
  }
}
