.product-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  gap: 2rem;
  margin-bottom: 5rem;
}
