.detail {
  display: flex;
  width: 100%;
  /* padding: 1rem 0; */
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .detail {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
