.product-container {
  width: 100;
  display: flex;
  gap: 3rem;
  margin-bottom: 5rem;
}
.carousel-box {
  width: 70%;
}
@media screen and (max-width: 800px) {
  .product-container {
    flex-direction: column;
  }
  .carousel-box {
    width: 100%;
  }
}
