.invoice {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  border-radius: 0.5rem;
  gap: 0.7rem;
  border: 1px solid #333;
}
.invoice-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .invoice {
    display: none;
  }
}
