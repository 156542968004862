.item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  padding-bottom: 2rem;
}
.image-box {
  width: 25%;
  height: 25vh;
  border: 1px solid black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.item-data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.3rem;
}

@media screen and (max-width: 800px) {
  .item {
    flex-wrap: wrap;
    gap: 8px;
    background-color: #ccc;
    padding: 0.5rem;
  }
  .image-box {
    width: 10rem;
  }
}
