.home {
  width: 100%;
  padding: 2rem 3rem 0rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width:900px) {
.home{
  padding: 0.3rem;
  gap: 1rem;
}
}