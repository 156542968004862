.search {
  width: 100%;
}
.search > input {
  border-radius: 3rem;
  border: "2px solid #000";
  width: inherit;
}
.searchbar-mini{
  display: none;
  margin-bottom: 16px ;
}

@media screen and (max-width:760px) {
  .search{
    position: absolute;
    visibility:hidden;
  }
  .searchbar-mini{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 24px 16px;
    background: #2E0052;
  }
  .searchbar-mini > input{
    width: 90%;
  }
}