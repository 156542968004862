.App {
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
  overflow-x: hidden;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.my-cart {
  display: flex;
}
.checkout-title {
  width: 100%;
  text-align: center;
}
.checked-out {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 70vw;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  border: 1px solid rgba(3, 3, 3, 0.4);
  box-shadow: 1px 10px 15px rgba(0, 0, 0, 0.2), 1px 10px 15px rgba(0, 0, 0, 0.2),
    1px 10px 15px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 800px) {
  .my-cart {
    display: none;
  }
  .checkout-title {
    text-align: left;
  }
  .checked-out {
    width: 90vw;
    padding: 2rem;
    text-align: center;
  }
}
