.footer {
  background-color: #2f0052;
  width: 100vw;
  padding: 0.4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  margin: 0 !important;
  z-index: 900;
}

@media screen and (max-width: 900px) {
  .footer {
    display: none;
  }
}
