.auth-layout {
  height: 100vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  justify-content: space-evenly;
}
.musicart {
  display: block;
  width: 100%;
  text-align: center;
}
.musicart-span {
  display: none;
}
@media screen and (max-width: 760px) {
  .auth-layout {
    width: 100vw;
    justify-content: flex-start;
    gap: 2rem;
  }
  .musicart {
    display: none;
  }
  .musicart-span {
    display: block;
  }
}
