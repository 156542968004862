.custom-button {
  background-color: #2e0052;
  color: white;
  padding: 0.5rem;
  border-radius: 0.4rem;
  width: 100%;
  margin-top: 0.5rem;
  border-width: 0;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
}

.custom-button:hover {
  cursor: pointer;
}
