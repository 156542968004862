.line-with-text {
  display: flex;
  align-items: center;
  width: 100%;
}

.line {
  flex: 3;
  border: none;
  border-top: 2px solid #3333335c;
}

.text {
  margin: 0 8px;
  color: #333;
  flex: 4;
  font-size: 0.8rem;
  text-align: center;
}
