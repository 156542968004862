.banner-container {
  width: 100%;
  background: linear-gradient(to right, #7286b4, #e794ce);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.text-box {
  flex: 3;
  margin: 0;
  padding: 0;
  font-size: 3rem;
  padding: 1.5rem;
  font-weight: 500;
  padding-left: 4rem;
}
.img-box {
  flex: 2;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
}
.img-box > img {
  width: 90%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width:900px) {
  .banner-container{
    height: 200px;
    border-radius: 7px;
  }
  .text-box{
    font-size: 32px;
    padding-left: 1rem;
  }
  .img-box > img{
    height: 235px;
    width: 260px;
  }
}

@media screen and (max-width:750px) {
  .banner-container{
    height: 200px;
    border-radius: 7px;
  }
  .text-box{
    font-size: 28px;
    padding-left: 1rem;
  }
  .img-box > img{
    height: 230px;
    width: 250px;
  }
}

@media screen and (max-width:600px) {
  .banner-container{
    height: 200px;
    border-radius: 7px;
  }
  .text-box{
    font-size: 24px;
    padding-left: 1rem;
  }
  .img-box > img{
    height: 230px;
    width: 225px;
  }
}

@media screen and (max-width:470px) {
  .text-box{
    font-size: 20px;
  }
  .img-box > img{
    height: 216px;
    width: 200px;
  }
}