.auth-title {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 0.8rem;
}
.little-auth-title {
  display: none;
  font-size: 1rem;
}
@media (max-width: 768px) {
  .auth-title {
    display: none;
  }
  .little-auth-title {
    display: flex;
    margin-bottom: 0.5rem;
  }
}
