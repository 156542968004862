.item-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 3;
  padding: 1.5rem;
  height: 60vh;
  overflow-y: scroll;
  margin-bottom: 2rem;
}
.total-bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 25rem;
}
@media screen and (max-width: 800px) {
  .total-bottom {
    gap: 1rem;
  }
}
@media screen and (min-width: 800px) {
  .hide-ingig {
    display: none;
  }
}
