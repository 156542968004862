.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 3rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    height: 75vh;
    overflow-y: visible;
    gap: 1rem;
  }
}
