.navbar {
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  /* margin-bottom: 1.5rem; */
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}

@media screen and (max-width:900px) {
  .navbar{
    position: absolute;
    visibility:hidden;
  }
}