.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.circular-progress {
  width: 2.5rem;
  height: 2.5rem;
  border: 4px solid transparent;
  border-color: rgba(0, 0, 0, 0.208);
  border-top-color: #146eaa;
  border-bottom-color: #146eaa;
  border-radius: 50%;
  animation: spin 0.9s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
