.image-box-item-detail {
  width: 50%;
  height: 25vh;
  border: 1px solid black;
}
.item-detail-item-detail {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .item-detail-item-detail {
    width: 100vw;
  }
}
