.auth-bottom-line {
  max-width: 25vw;
  word-wrap: break-word;
  margin-top: 1rem;
}

@media screen and (max-width:700px) {
  .auth-bottom-line{
    max-width: 75vw;
  }
}